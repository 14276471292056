/**
 * Const with params element
 * Card Resource component
 * Display a card of summary Resource
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import TruncateText from "../components/truncatetext"
import "../styles/components/cardresource.scss"

/* Declaration function */
const Cardresource = ({ titleResource, leadResource, srcImgResource, slugResource, typeSlugResource, typeNameResource, colorResource }) => {

  const classWithoutPicture = (typeSlugResource !== "whitepapers") ? "card-without-picture" : ""

  return (
    <div className={`card-resource card-clickable ${classWithoutPicture}`}>
      {typeSlugResource === "whitepapers" && (
        <div className="box-image-card picture-resource">
         <GatsbyImage image={srcImgResource} alt={`picture ${titleResource}`} />
        </div>
      )}
      <p className={`type-resource ${colorResource}`}><span>{typeNameResource}</span></p>
      <h2><TruncateText str={titleResource} length="60" ending="..." /></h2>
      <div className="box-lead">
        <p><TruncateText str={leadResource} length="200" ending="..." /></p>
      </div>
      <div className="box-ctatext-card">
        <Ctabuttonwitharrow ctaLabel="Read More" ctaLink={`/${typeSlugResource}/${slugResource}`} specificStyle="text-arrow" colorStyle="white-format" />
      </div>
      <a href={`/${typeSlugResource}/${slugResource}/`} aria-label="Read More"><span className="span-cta-card" >Read More</span></a>
    </div>
  )
}

/* Export function */
export default Cardresource

