/**
 * Const with params element
 * Render Resource component
 * Render the number of post of Block Resource
 */
/* Import section */
import React from "react"
import Cardresource from "../components/cardresource"

/* Declaration function */
const Renderresource = ({ postsToRender, colorResource }) => {
  return (
    <div className="wrap-loop-resources grid-loop-card-clickable">
      {postsToRender.map(({ node }) => (
        <Cardresource
          key={node.databaseId}
          titleResource={node.title}
          leadResource= {(node.acfPostResource.introResource !== null) ? node.acfPostResource.introResource:node.acfPostResource.contentResource}
          srcImgResource={(node.acfPostResource.pictureResource !== null) ? node.acfPostResource.pictureResource.localFile.childImageSharp.gatsbyImageData:null}
          slugResource={node.slug}
          typeSlugResource={node.catsresources.nodes[0].slug}
          typeNameResource={node.catsresources.nodes[0].name}
          colorResource={colorResource}
        />
      ))}
    </div>
  )
}

/* Export function */
export default Renderresource

