/**
 * Resource list Template
 */
/* Import section */
import { graphql } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Renderresource from "../components/renderresource"
import Seo from "../components/seo"
import "../styles/templates/resourcelist.scss"

const postsPerPage = 6
let arrayForHoldingPosts = []

/* Declaration class */
const ResourceListTemplate = ({ data }) => {

  //Categorie resources infos
  const dataResources = data.allWpResource
  const dataCatResources = dataResources.edges[0].node.catsresources.nodes[0]
  const h1CatResource = dataCatResources.acfCatResources.titleH1Tag
  const colorCatResource = dataCatResources.acfCatResources.colorTag
  const descCatResource = dataCatResources.acfCatResources.descriptionResource
  const nameCatResource = dataCatResources.name
  const slugCatResource = dataCatResources.slug
  const seoCatResource = dataCatResources.seo

  //Load More functionnality
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(6)
  const [slugPost, setSlugPost] = useState(0)
  const prevSlugPostRef = useRef()
  const prevSlugPost = prevSlugPostRef.current
  const lengthPostsToShow = postsToShow.length
  const postsresources = dataResources.edges
  const lenghtPostsResources = postsresources.length
  var showLoadMore = (lenghtPostsResources > postsPerPage && lenghtPostsResources !== lengthPostsToShow) ? true : false

  if(!showLoadMore || prevSlugPost === undefined) {
    arrayForHoldingPosts = []
  }

  const loopWithSlice = (start, end) => {
    const slicedPosts = postsresources.slice(start, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
    prevSlugPostRef.current = slugPost
  }, [])

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
    setSlugPost(dataResources.slug)
  }

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []

  if(slugCatResource === "whitepapers") {
    arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
      return element.topicBannerCta === "webinars"
    })
  }else if(slugCatResource === "webinars") {
    arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
      return element.topicBannerCta === "whitepapers"
    })
  }else if(slugCatResource === "podcasts") {
    arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
      return element.topicBannerCta === "whitepapers"
    })
  }else if(slugCatResource === "case-studies") {
    arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
      return element.topicBannerCta === "testimonials"
    })
  }

  return (
    <Layout>
      <Seo
        title={seoCatResource.title}
        description={seoCatResource.metaDesc}
      />

      <section className="section-hero-resource-list top-section">
        <Herotype2 h1title={h1CatResource} textIntro={descCatResource} colorBar="white" />
      </section>

      {/* Section with loop on all resources */}
      <section className="section-loop-resources">
        <Renderresource postsToRender={postsToShow} colorResource={colorCatResource}/>
        {showLoadMore &&
        <div className="box-loadmore">
          <button onClick={handleShowMorePosts}>Load More {nameCatResource}</button>
        </div>
        }
      </section>

      {/* Banner CTA */}
      <Bannerwithcta
        classBanner="section-banner-resources"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default ResourceListTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($slug: String!) {
    allWpResource(filter: {catsresources: {nodes: {elemMatch: {slug: {eq: $slug}}}}}, sort: {fields: [date], order:DESC}) {
      edges {
        node {
          title
          slug
          acfPostResource {
            audioLinkResource
            contentResource
            fileResource
            hubspotIdFormResource
            introResource
            videoLinkResource
            pictureResource {
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 800)
                }
              }
            }
          }
          catsresources {
            nodes {
              acfCatResources {
                colorTag
                descriptionResource
                titleH1Tag
              }
              description
              name
              slug
              seo {
                title
                metaDesc
              }
            }
          }
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
